<template>
  <v-container fluid class="pr-0 pl-0">
    <v-card elevation="0">
      <v-toolbar flat>
        <h2 class="heading font-weight-normal grey--text">{{ $t('reports.title') }}</h2>
      </v-toolbar>
      <v-col xs="12" md="12">
        <v-row class="pt-0 mt-0">
          <v-col cols="12" xs="12" sm="12" md="4">
            <DateRange
              :groupBy="Reports.state.options.groupBy"
              ref="dateRange"
              :value="Reports.state.filters.dateRange"
              @input="[Reports.actions.applyFilter({ key: 'dateRange', value: $event })]"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="8">
            <v-row class="pt-0 mt-0">
              <v-col cols="12" xs="12" sm="12" md="6">
                <span class="subtitle-1 font-weight-light">Country</span>
                <v-autocomplete
                  v-model="Reports.state.filters.country"
                  :items="Reports.state.countries"
                  item-text="name"
                  item-value="code"
                  label="Country"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="[Reports.actions.applyFilter({ key: 'country', value: $event })]"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        [
                          Reports.actions.applyFilter({
                            key: 'country',
                            value: removeOnList(Reports.state.filters.country, data.item.code),
                          }),
                        ]
                      "
                      class="ma-1"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span
                  v-if="Application.state.user.roles.offers"
                  class="subtitle-1 font-weight-light"
                  >Offer</span
                >
                <v-autocomplete
                  v-if="Application.state.user.roles.offers"
                  v-model="Reports.state.filters._offer"
                  :items="Reports.state.offers"
                  :filter="autocompleteFilter"
                  item-text="name"
                  item-value="_id"
                  label="Offer"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="[Reports.actions.applyFilter({ key: '_offer', value: $event })]"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        [
                          Reports.actions.applyFilter({
                            key: '_offer',
                            value: removeOnList(Reports.state.filters._offer, data.item._id),
                          }),
                        ]
                      "
                      class="ma-1"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <span class="subtitle-1 font-weight-light">Device Brand</span>
                <v-autocomplete
                  v-model="Reports.state.filters.deviceBrand"
                  :items="Devices.state.DeviceBrandItems"
                  item-text="name"
                  item-value="_id"
                  :loading="Devices.state.DeviceBrandItemsLoading"
                  label="Device Brand"
                  :disabled="disableClientBrowserFilter()"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="[Reports.actions.applyFilter({ key: 'deviceBrand', value: $event })]"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        [
                          Reports.actions.applyFilter({
                            key: 'deviceBrand',
                            value: removeOnList(Reports.state.filters.deviceBrand, data.item._id),
                          }),
                        ]
                      "
                      class="ma-1"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span class="subtitle-1 font-weight-light">Device Client</span>
                <v-autocomplete
                  v-model="Reports.state.filters.deviceClient"
                  :items="Devices.state.DeviceClientItems"
                  :loading="Devices.state.DeviceClientItemsLoading"
                  label="Device Client"
                  :disabled="disableClientBrowserFilter()"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="[Reports.actions.applyFilter({ key: 'deviceClient', value: $event })]"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        [
                          Reports.actions.applyFilter({
                            key: 'deviceClient',
                            value: removeOnList(Reports.state.filters.deviceClient, data.item),
                          }),
                        ]
                      "
                      class="ma-1"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span class="subtitle-1 font-weight-light">Device Os</span>
                <v-autocomplete
                  v-model="Reports.state.filters.deviceOs"
                  :items="Devices.state.DeviceOsItems"
                  :loading="Devices.state.DeviceOsItemsLoading"
                  label="Device Os"
                  :disabled="disableClientBrowserFilter()"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="[Reports.actions.applyFilter({ key: 'deviceOs', value: $event })]"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        [
                          Reports.actions.applyFilter({
                            key: 'deviceOs',
                            value: removeOnList(Reports.state.filters.deviceOs, data.item),
                          }),
                        ]
                      "
                      class="ma-1"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span class="subtitle-1 font-weight-light">Device Type</span>
                <v-autocomplete
                  v-model="Reports.state.filters.deviceType"
                  :items="Devices.state.DeviceTypeItems"
                  :loading="Devices.state.DeviceTypeItemsLoading"
                  label="Device Type"
                  :disabled="disableClientBrowserFilter()"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="[Reports.actions.applyFilter({ key: 'deviceType', value: $event })]"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        [
                          Reports.actions.applyFilter({
                            key: 'deviceType',
                            value: removeOnList(Reports.state.filters.deviceType, data.item),
                          }),
                        ]
                      "
                      class="ma-1"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span class="subtitle-1 font-weight-light">Browser</span>
                <v-autocomplete
                  v-model="Reports.state.filters.deviceClientBrowser"
                  :items="deviceClientBrowser"
                  item-text="name"
                  item-value="_id"
                  label="Browser"
                  :disabled="disableClientBrowserFilter()"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="
                    [Reports.actions.applyFilter({ key: 'deviceClientBrowser', value: $event })]
                  "
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        [
                          Reports.actions.applyFilter({
                            key: 'deviceClientBrowser',
                            value: removeOnList(
                              Reports.state.filters.deviceClientBrowser,
                              data.item._id
                            ),
                          }),
                        ]
                      "
                      class="ma-1"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" xs="12" sm="12">
                <v-row class="pt-0 mt-0">
                  <v-col cols="12" xs="12" sm="12">
                    <span class="subtitle-1 font-weight-light">Headers</span>

                    <v-autocomplete
                      v-model="Reports.state.options.headers"
                      :items="filteredHeaders()"
                      item-text="name"
                      item-value="value"
                      label="Headers"
                      :multiple="true"
                      solo
                      chips
                      hide-details
                      dense
                      flat
                      clearable
                      solo-inverted
                      prepend-inner-icon="mdi-cursor-pointer"
                      @input="[Reports.actions.applyHeaders({ value: $event })]"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          color="blue lighten-1"
                          dark
                          small
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          class="ma-1"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                    <span class="subtitle-1 font-weight-light">Calculations</span>

                    <v-autocomplete
                      v-model="Reports.state.calculations"
                      :items="allowedCalculations"
                      label="Calculations"
                      item-text="name"
                      item-value="value"
                      return-object
                      :multiple="true"
                      solo
                      chips
                      hide-details
                      dense
                      clearable
                      flat
                      solo-inverted
                      prepend-inner-icon="mdi-cursor-pointer"
                      @input="Reports.actions.applyCalculations({ value: $event })"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          color="blue lighten-1"
                          dark
                          small
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          class="ma-1"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <span class="subtitle-1 font-weight-light">Group By</span>
            <v-select
              v-model="Reports.state.options.groupBy"
              item-text="name"
              item-value="value"
              :items="allowedGroups"
              label="Group By"
              solo
              chips
              hide-details
              dense
              flat
              solo-inverted
              prepend-inner-icon="mdi-cursor-pointer"
              @input="groupByHandler($event)"
            >
              <template v-slot:selection="data">
                <v-chip
                  color="blue lighten-1"
                  dark
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  class="ma-1"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="12">
            <v-card-actions class="pa-5">
              <v-spacer />
              <p v-if="Reports.state.isExportOnly" style="color: red" class="my-2 mx-5 subtitle-1">
                By Date Aff_sub reports can only be exported.
              </p>
              <p
                v-if="Reports.state.showErrorDescriptionCsv"
                style="color: red"
                class="my-2 mx-5 subtitle-1"
              >
                {{ Reports.state.errorDescriptionCsv }}
              </p>
              <v-btn
                @click="loadAndShow(Reports, show)"
                small
                depressed
                class="ml-6"
                :loading="Reports.state.isLoadingRunReportButton"
                :disabled="Reports.state.isExportOnly"
              >
                <v-icon class="mr-1">{{ Icons.send }}</v-icon>
                <span class="text-capitalize">Run Report</span>
              </v-btn>
              <v-btn
                @click="Reports.actions.exportCsv()"
                v-if="Reports.state.showExportButton"
                small
                depressed
                class="ml-6"
                :disabled="Reports.state.disableExportButton"
              >
                <span class="material-icons downIcon">download</span>
                <span v-if="Reports.state.showExportReport" class="text-capitalize"
                  >Export Report</span
                >
                <div class="customLoadingWithText" v-if="Reports.state.showExportingReport">
                  <v-progress-circular
                    indeterminate
                    size="20"
                    color="$vuetify.theme.dark ? 'white' : 'black'"
                  ></v-progress-circular>
                  <span class="text-capitalize exporting-report">Exporting Report</span>
                </div>
              </v-btn>
              <v-btn
                @click="Reports.actions.downloadButtonHandler()"
                small
                depressed
                class="ml-6"
                v-else-if="Reports.state.showDownloadButton"
                :href="Reports.state.csvUrl"
                target="_blank"
              >
                <span class="material-icons downIcon">download</span>
                <span v-if="Reports.state.doneCsv" class="text-capitalize">Download Report</span>
              </v-btn>
              <v-btn
                small
                style="width: 110px"
                :class="'disable-events'"
                depressed
                class="ml-6"
                color="red"
                v-else
              >
                <span class="material-icons downIcon">download</span>
                <span v-if="Reports.state.doneCsv" class="text-capitalize"> ERROR </span>
              </v-btn>
            </v-card-actions>
            <CalculationTotals
              :items="Reports.state.totals"
              :calculations="
                Reports.state.calculations.filter(
                  (calculation) =>
                    !calculation.isHeader ||
                    Reports.state.options.headers.includes(calculation.value)
                )
              "
              :show="show"
              v-if="show"
            />
          </v-col>
        </v-row>
      </v-col>

      <data-table
        ref="dataTable"
        :headers="Reports.state.headers"
        v-bind:items="Reports.state.items"
        v-bind:loading="Reports.state.loading"
        :server-items-length="Reports.state.total"
        :options.sync="Reports.state.pagination"
        @paginate="Reports.actions.applyOptions({ value: $event })"
      >
        <template v-slot:item.year="{ item }">{{ item.createdAt | dateYear }}</template>
        <template v-slot:item.month="{ item }">{{ item.createdAt | dateMonthYear }}</template>
        <template v-slot:item.week="{ item }">{{ item.date.week }}</template>
        <template v-slot:item.day="{ item }">{{ item.createdAt | date }}</template>
        <template v-slot:item.hour="{ item }">{{ item.createdAt }}</template>
        <template v-slot:item.minute="{ item }">{{ item.createdAt | dateTime }}</template>
        <template v-slot:item._offer="{ item }">{{ getOfferName(item) }} </template>
        <template v-slot:item.country="{ item }"> {{ getCountryName(item) }}</template>
        <template v-slot:item.deviceBrand="{ item }">{{ getDeviceBrandName(item) }}</template>
        <template v-slot:item.deviceClient="{ item }">{{
          item.meta ? item.meta.deviceClient : '-'
        }}</template>
        <template v-slot:item.deviceOs="{ item }">{{
          item.meta ? item.meta.deviceOs : '-'
        }}</template>
        <template v-slot:item.deviceType="{ item }">{{
          item.meta ? item.meta.deviceType : '-'
        }}</template>
        <template v-slot:item.deviceClientBrowser="{ item }">{{
          item.meta && item.meta.deviceClientBrowser ? getClientBrowserName(item) : '-'
        }}</template>
        <template v-slot:item.converted="{ item }">{{
          item.meta ? item.meta.converted : '-'
        }}</template>
        <template v-slot:item.aff_sub="{ item }">{{
          item.meta ? item.meta.aff_sub : '-'
        }}</template>
        <template v-slot:item.aff_sub1="{ item }">{{
          item.meta ? item.meta.aff_sub1 : '-'
        }}</template>
        <template v-slot:item.aff_sub2="{ item }">{{
          item.meta ? item.meta.aff_sub2 : '-'
        }}</template>
        <template v-slot:item.aff_sub3="{ item }">{{
          item.meta ? item.meta.aff_sub3 : '-'
        }}</template>
        <template v-slot:item.aff_sub4="{ item }">{{
          item.meta ? item.meta.aff_sub4 : '-'
        }}</template>
        <template v-slot:item.aff_sub5="{ item }">{{
          item.meta ? item.meta.aff_sub5 : '-'
        }}</template>
        <template v-slot:item.transactions="{ item }">{{
          item.transactions | integerFormat
        }}</template>
        <template v-slot:item.uniqueTransaction="{ item }">{{
          item.uniqueTransaction | integerFormat
        }}</template>
        <template v-slot:item.conversions="{ item }">{{
          item.conversions | integerFormat
        }}</template>
        <template v-slot:item.cr="{ item }">{{ item.cr | floatFormatReports }} % </template>
        <template v-slot:item.epcEUR="{ item }"
          >{{ Reports.state.symbol }} {{ item[`epc${affiliateCurrency}`] | floatFormatReports }}
        </template>
        <template v-slot:item.epcUSD="{ item }"
          >{{ Reports.state.symbol }} {{ item[`epc${affiliateCurrency}`] | floatFormatReports }}
        </template>
        <template v-slot:item.payoutEUR="{ item }">
          {{ Reports.state.symbol }} {{ item[`payout${affiliateCurrency}`] | floatFormatCommons }}
        </template>
        <template v-slot:item.payoutUSD="{ item }"
          >{{ Reports.state.symbol }} {{ item[`payout${affiliateCurrency}`] | floatFormatCommons }}
        </template>
      </data-table>
    </v-card>
  </v-container>
</template>
<script>
import { inject, onMounted, onUnmounted } from '@vue/composition-api';
import { indexOf, intersection } from 'lodash';
import moment from 'moment-timezone';
import DataTable from '../Components/DataTable';
import DateRange from '../Components/DateRange';
import CalculationTotals from '../Components/CalculationTotals.vue';
import Countries from '../../store/constants/Countries';
import devices from '../../store/constants/DeviceModel';

export default {
  name: 'reports-ts',
  components: {
    DataTable,
    DateRange,
    CalculationTotals,
  },
  data: () => {
    return {
      show: false,
    };
  },
  setup(props, context) {
    const Application = inject('Application');
    const Reports = inject('ReportTS');
    const Devices = inject('Devices');
    const deviceClientBrowser = devices.deviceClientBrowser;
    const affiliateCurrency = Reports.actions.affiliateCurrency();
    const getCountryName = (item) => {
      if (item.countryName) {
        return item.countryName;
      } else if (item.meta && item.meta.country) {
        return Countries[item.meta.country];
      } else {
        return '-';
      }
    };

    const getOfferName = (item) => {
      if (item.offerName) {
        return item.offerName;
      } else if (item.offer && item.offer.name) {
        return item.offer.name;
      } else {
        return '-';
      }
    };
    const getDeviceBrandName = (item) => {
      const { meta } = item;
      if (meta && meta.deviceBrand === 'unknown') {
        return 'unknown';
      } else if (meta && meta.deviceBrand) {
        const deviceBrand = devices.deviceBrand.find(
          (deviceBrandObj) => deviceBrandObj._id === meta.deviceBrand
        );
        return deviceBrand ? deviceBrand.name : '-';
      } else {
        return '-';
      }
    };
    const removeOnList = (list, value) => {
      const index = indexOf(list, value);
      if (index >= 0) {
        list.splice(index, 1);
      }
      return list;
    };
    const allowedGroups = [
      { name: 'Year', value: 'year' },
      { name: 'Month', value: 'month' },
      { name: 'Week', value: 'week' },
      { name: 'Day', value: 'day' },
      { name: 'Hour', value: 'hour' },
      { name: 'Minute', value: 'minute' },
    ];

    const allowedHeaders = [
      {
        name: 'Offer',
        value: '_offer',
      },
      {
        name: 'Country',
        value: 'country',
      },
      {
        name: 'Device Brand',
        value: 'deviceBrand',
      },
      {
        name: 'Device Client',
        value: 'deviceClient',
      },
      {
        name: 'Device Os',
        value: 'deviceOs',
      },
      {
        name: 'Device Type',
        value: 'deviceType',
      },
      { name: 'Browser', value: 'deviceClientBrowser' },
      {
        name: 'Converted',
        value: 'converted',
      },
      {
        name: 'Unique Clicks',
        value: 'uniqueTransaction',
      },
      {
        name: 'Aff_sub',
        value: 'aff_sub',
      },
      {
        name: 'Aff_sub1',
        value: 'aff_sub1',
      },
      {
        name: 'Aff_sub2',
        value: 'aff_sub2',
      },
      {
        name: 'Aff_sub3',
        value: 'aff_sub3',
      },
      {
        name: 'Aff_sub4',
        value: 'aff_sub4',
      },
      {
        name: 'Aff_sub5',
        value: 'aff_sub5',
      },
    ];
    const allowedCalculations = [
      {
        name: 'CR',
        value: 'cr',
      },
      {
        name: 'EPC',
        value: `epc${affiliateCurrency}`,
      },
      {
        name: 'Payout',
        value: `payout${affiliateCurrency}`,
      },
      {
        name: 'Raw Clicks',
        value: 'transactions',
      },
      {
        name: 'Conversions',
        value: 'conversions',
      },
    ];
    const filteredHeaders = () => {
      const groupByOptions = Reports.state.options.groupBy;
      if (groupByOptions == 'day') {
        return [
          {
            name: 'Offer',
            value: '_offer',
          },
          {
            name: 'Country',
            value: 'country',
          },
          {
            name: 'Device Brand',
            value: 'deviceBrand',
          },
          {
            name: 'Device Client',
            value: 'deviceClient',
          },
          {
            name: 'Device Os',
            value: 'deviceOs',
          },
          {
            name: 'Device Type',
            value: 'deviceType',
          },
          { name: 'Browser', value: 'deviceClientBrowser' },
          {
            name: 'Unique Clicks',
            value: 'uniqueTransaction',
          },
          {
            name: 'Aff_sub',
            value: 'aff_sub',
          },
          {
            name: 'Aff_sub1',
            value: 'aff_sub1',
          },
          {
            name: 'Aff_sub2',
            value: 'aff_sub2',
          },
          {
            name: 'Aff_sub3',
            value: 'aff_sub3',
          },
          {
            name: 'Aff_sub4',
            value: 'aff_sub4',
          },
          {
            name: 'Aff_sub5',
            value: 'aff_sub5',
          },
        ];
      } else if (
        groupByOptions == 'week' ||
        groupByOptions == 'month' ||
        groupByOptions == 'year'
      ) {
        return [
          {
            name: 'Offer',
            value: '_offer',
          },
          {
            name: 'Country',
            value: 'country',
          },
          {
            name: 'Unique Clicks',
            value: 'uniqueTransaction',
          },
        ];
      } else {
        return allowedHeaders;
      }
    };
    onMounted(async () => {
      await Reports.actions.loadFilterItems();
    });
    onUnmounted(async () => {
      await Reports.actions.resetInitialValues();
    });
    const groupByHandler = (event) => {
      const allowedDevicesGroupBy = ['minute', 'hour', 'day'];
      const devicesCategories = [
        'deviceBrand',
        'deviceType',
        'deviceOs',
        'deviceClient',
        'deviceClientBrowser',
      ];

      if (!allowedDevicesGroupBy.includes(event)) {
        devicesCategories.forEach((deviceCategory) => {
          Reports.actions.removeFilter({ key: deviceCategory });
        });
      }
      Reports.actions.applyGroupBy({ value: event });
      if (event === 'minute' || event === 'hour') {
        context.refs.dateRange.input({
          start: moment().startOf('day').format('YYYY-MM-DD'),
          end: moment().endOf('day').format('YYYY-MM-DD'),
        });
      }
      const filteredHeadersValues = filteredHeaders().map((value) => value.value);
      Reports.actions.applyHeadersOptions({
        value: intersection(Reports.state.options.headers, filteredHeadersValues),
      });
    };

    const disableClientBrowserFilter = () => {
      const allowedGroupBy = ['day', 'hour', 'minute'];
      return allowedGroupBy.includes(Reports.state.options.groupBy) ? false : true;
    };

    const getClientBrowserName = (item) => {
      const browser = devices.deviceClientBrowser.find((browser) => {
        return browser._id === item.meta.deviceClientBrowser;
      });
      return browser ? browser.name : 'unknown';
    };

    const autocompleteFilter = (item, queryText) => {
      return (
        String(item._id).toLowerCase().includes(queryText.toLowerCase()) ||
        item.name.toLowerCase().includes(queryText.toLowerCase())
      );
    };

    return {
      Application,
      Reports,
      Devices,
      allowedGroups,
      allowedHeaders,
      allowedCalculations,
      removeOnList,
      Countries,
      groupByHandler,
      filteredHeaders,
      affiliateCurrency,
      deviceClientBrowser,
      getClientBrowserName,
      disableClientBrowserFilter,
      getCountryName,
      getOfferName,
      getDeviceBrandName,
      autocompleteFilter,
    };
  },
  methods: {
    async loadAndShow(rep, show) {
      this.show = true;
      rep.actions.updateAttribute({ key: 'initialState', value: false });
      rep.actions.resetCsvValues();
      this.$refs.dataTable.resetPage();
      await rep.actions.loadItems();
    },
  },
};
</script>
<style lang="scss">
.disable-events {
  pointer-events: none;
}
</style>
